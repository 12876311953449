export const externalUrls = Object.freeze({
	existingSubscriber:
		'https://discover.dragonforms.com/loading.do?omedasite=DSC_Renewsub',
	newsletterUnsubscribe:
		'https://discover.dragonforms.com/loading.do?omedasite=discover_unsub',
	newsletterChangeEmail:
		'https://discover.dragonforms.com/loading.do?omedasite=discover_changeem',
	privacyPolicy: 'https://www.discovermagazine.com/privacy',
	resetPassword:
		'https://discover.dragonforms.com/loading.do?omedasite=DSC_Renewsub',
	subscribe: 'https://discover.dragonforms.com/loading.do?omedasite=DSC_NewSub',
	// subscribeRenew:
	// 	'https://kalmbach.dragonforms.com/loading.do?omedasite=DSS_New&pk=DSF43D1',
	subscribeMeterBanner:
		'https://discover.dragonforms.com/loading.do?omedasite=DSC_NewSub',
	subscribePaywallPrompt:
		'https://discover.dragonforms.com/loading.do?omedasite=DSC_NewSub',
	termsOfUse: 'https://www.discovermagazine.com/terms'
})
